import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../config';

const VerifyDialog = () =>
{
	const history = useHistory();
	const urlParser = new URL(window.location);
	const token = urlParser.searchParams.get('token');
	const [ message, setMessage ] = useState(null);
	const centeringStyle = {
		'display'        : 'flex',
		'flexDirection'  : 'column',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'height'         : '100vh'
	};
	const [ shouldRedirect, setShouldRedirect ] = useState(null);

	function redirectBack()
	{
		window.history.back();
	}

	useEffect(() =>
	{
		if (config.isAPIonly)
		{
			let leastLoadedHost = [];

			const checkHostIsOnline = async () =>
			{
				try
				{
					if (leastLoadedHost.length > 0)
					{
						const host = leastLoadedHost.splice(0, 1)[0];

						setMessage('Connecting to server');
						setShouldRedirect(false);

						const controller = new AbortController();
						const reqTimeout = setTimeout(
							() => controller.abort(),
							config.hostCheckTimeout
						);

						const response = await fetch(`https://${host}/stats/is-online`, {
							method  : 'GET',
							cache   : 'no-cache',
							headers : {
								'x-token'      : token,
								'Content-Type' : 'application/json',
								'Accept'       : 'application/json'
							},
							signal : controller.signal
						});

						clearTimeout(reqTimeout);

						if (response.status === 200)
							window.open(`https://${host}/verify?token=${token}`, '_self');
						else
							await checkHostIsOnline();
					}
					else
					{
						setMessage('Failed to find the server.');
						setShouldRedirect(true);
					}
				}
				catch (error)
				{
					setMessage(error.message);
					await checkHostIsOnline();
				}
			};

			const fetchData = async () =>
			{
				const response = await fetch('/auth/verify', {
					method  : 'GET',
					cache   : 'no-cache',
					headers : {
						'x-token'      : token,
						'Content-Type' : 'application/json',
						'Accept'       : 'application/json'
					}
				});
				const body = await response.json();

				if (response.status === 200)
				{
					if (body.command === 'redirect')
					{
						if (body.least_loaded_hosts)
						{
							leastLoadedHost = body.least_loaded_hosts;
							await checkHostIsOnline();
						}
						if (body.meeting_host)
						{
							window.open(`https://${body.meeting_host}/verify?token=${token}`, '_self');
						}
					}
					else
					{
						let searchParams = [ '?displayName=', body.data.displayName, '&token=', token ];

						if (body.data.isBot)
						{
							searchParams = [ '?displayName=', 'Recording Bot', '&token=', token, '&headless=', true ];
						}

						const searchString = searchParams.join('');

						history.push({
							pathname : encodeURIComponent(body.data.roomId),
							search   : searchString
						});
						window.history.replaceState({}, null, [ encodeURIComponent(body.data.roomId), searchString ].join('') || '/');
					}
				}
				else
				{
					setMessage(body.message);
					setShouldRedirect(true);
				}
			};

			fetchData();
		}
		else
		{
			window.location = '/';
		}
	}, [ token ]);

	return (
		<div style={centeringStyle}>
			{!message && <h1 style={centeringStyle}>
				<span className='loader loader-quart' />
				Loading
			</h1>}
			{message && <p>
				<h1 className='text-center'>{message}</h1>
				{shouldRedirect && <h4 className='text-center'>
					<a className='anchorTag' onClick={redirectBack}>
						Click here to go back to previous page
					</a>
				</h4>}
			</p>}
		</div>
	);
};

VerifyDialog.propTypes = {};

VerifyDialog.defaultProps = {};

export default VerifyDialog;
